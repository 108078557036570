import {ajaxPost, cLog} from '@js/humans/helper';

/**
 * Claim Form Handler
 * @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
 */
export default class ClaimFormHandler {
    /**
     * UI references
     */
    protected ui = {
        mainForm: '.main__form',
        claimForm: '.js-claim-form',
        responseSuccess: '.main__form-response--success',
        responseError: '.main__form-response--error',
    };

    /**
     * Constructor
     */
    public constructor() {
        this.registerEvents();
    }

    /**
     * Register required events
     */
    protected registerEvents(): void {
        const form = document.querySelector(this.ui.mainForm);
        if (form) {
            form.addEventListener('submit', this.onFormSubmit.bind(this));
        }
    }

    /**
     * Handles the claim form submission
     */
    protected async onFormSubmit(event: SubmitEvent | Event): Promise<void>
    {
        event.preventDefault();

        const form = event.target as HTMLFormElement;
        const button = form.querySelector('button') as HTMLButtonElement;
        button.disabled = true;

        const formData = new FormData(form);

        try {
            const {data} = await ajaxPost(form.getAttribute('action') ?? '', formData);
            if (data.success) {
                this.showSuccess()
            } else {
                this.showError();
            }
        } catch (error) {
            this.showError();
        }

        button.disabled = false;
    }

    /**
     * Shows the success message
     */
    protected showSuccess()
    {
        document.querySelector(this.ui.mainForm)?.setAttribute('aria-hidden', 'true');
        document.querySelector(this.ui.responseSuccess)?.setAttribute('aria-hidden', 'false');
        document.querySelector(this.ui.responseError)?.setAttribute('aria-hidden', 'true');
    }

    /**
     * Shows the error message
     */
    protected showError()
    {
        document.querySelector(this.ui.responseSuccess)?.setAttribute('aria-hidden', 'true');
        document.querySelector(this.ui.responseError)?.setAttribute('aria-hidden', 'false');
    }
}
