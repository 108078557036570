import '../styles/main.scss';
// import 'vite/modulepreload-polyfill';
// import $ from 'cash-dom';
// import './humans/cash-dom/mixins';
// import ImgFade from '@js/zal/img-fade';
import ClaimFormHandler from '@js/zal/claim-form-handler';

// Create module instances
// new ImgFade();
new ClaimFormHandler();

// Accept hot module reloading
if (import.meta.hot) {
    // Dev related
    import('./humans/dev/vite.js').then((module) => {
        module.default();
    });

    // Enable HMR
    import.meta.hot.accept();

    // Show a message, when a specific file was reloaded
    // @ts-ignore
    import.meta.hot.on('vite:afterUpdate', (update: UpdatePayload) => {
        // @ts-ignore
        update.updates.forEach((upd: Update) => {
            let info = {msg: 'HMR Update', color: '#000', background: '#17ef79'};
            if (/\.scss$/i.test(upd.path)) {
                info = {msg: 'SCSS Update', color: '#fff', background: '#CE679B'};
            } else if (/\.ts$/i.test(upd.path)) {
                info = {msg: 'TS Update', color: '#fff', background: '#2D79C7'};
            } else if (/\.js$/i.test(upd.path)) {
                info = {msg: 'JS Update', color: '#000', background: '#F0D81D'};
            } else if (/\.vue$/i.test(upd.path)) {
                info = {msg: 'VUE Update', color: '#32475B', background: '#3EB27F'};
            }

            // eslint-disable-next-line no-console, max-len
            console.log(`%c🎉 ${info.msg}!`, `color: ${info.color}; background: ${info.background}; font-weight: bold; display: inline-block; border-radius: 4px; padding: 3px 6px;`);
        });
    })

    // Remove previously added event listeners for body and window
    import.meta.hot.dispose((data) => {

    });
}