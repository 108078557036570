import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';

const logPrefix = 'ZAL';

/**
 * Internal method for log style
 * @param {string} background Background color
 * @param {string} text Text color
 * @return {string} The console style
 */
function logStyle(background: string, text: string = '#fff'): string {
    if (!/^#/.test(background)) {
        background = `#${background}`;
    }

    if (!/^#/.test(text)) {
        text = `#${text}`;
    }

    // Convert to gradient
    background = `linear-gradient(180deg, ${lightenDarkenColor(background, 25)} 0%, ${lightenDarkenColor(background, -25)} 100%)`;

    return `background: ${background}; color: ${text}; font-weight: bold; font-size: 12px; display: inline-block; border-radius: 4px; padding: 4px 8px`;
}

/**
 * Lightens/Darkens color for a specific amount
 */
function lightenDarkenColor(color: string, amount: number) {
    let usePound = false;
    if (color[0] == '#') {
        color = color.slice(1);
        usePound = true;
    }

    let num = parseInt(color, 16);

    let r = (num >> 16) + amount;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amount;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000FF) + amount;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

/**
 * Internal log method
 * @param {*=}    message  Message to show
 * @param {...*=} optional Additional messages to show
 */
function cLog(message: any, ...optional: any[]): void {
    if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log(`%c${logPrefix}`, logStyle('#606060'), message, ...optional);
    }
}

/**
 * Internal log method
 * @param {*=}    message  Message to show
 * @param {...*=} optional Additional messages to show
 */
function cInfo(message: any, ...optional: any[]): void {
    if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log(`%c${logPrefix}`, logStyle('#339cdc'), message, ...optional);
    }
}

/**
 * Internal log method
 * @param {*=}    message  Message to show
 * @param {...*=} optional Additional messages to show
 */
function cWarn(message: any, ...optional: any[]): void {
    if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log(`%c${logPrefix}`, logStyle('#e8de20', '#000'), message, ...optional);
    }
}

/**
 * Internal log method
 * @param {*=}    message  Message to show
 * @param {...*=} optional Additional messages to show
 */
function cError(message: any, ...optional: any[]): void {
    if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.log(`%c${logPrefix}`, logStyle('#ed1a1a'), message, ...optional);
    }
}

let axiosInstance: AxiosInstance | null = null;
function getAxiosInstance(): AxiosInstance {
    if (!axiosInstance) {
        axiosInstance =  axios.create({
            headers: {'X-Requested-With': 'XMLHttpRequest'},
        });
    }

    return axiosInstance;
}

/**
 * Wrapper for making an ajax get request
 */
function ajaxGet<R = AxiosResponse>(url: string, config?: AxiosRequestConfig): Promise<R> {
    const axios = getAxiosInstance();

    return axios.get(url, config);
}

/**
 * Wrapper for making an ajax post request
 */
function ajaxPost<R = AxiosResponse>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    const axios = getAxiosInstance();

    return axios.post(url, data, config);
}

/**
 * Returns a clean URL
 * @param {string | null} url The input URL or if empty the current URL
 * @returns {string} The clean URL
 */
function getCleanUrl(url: string | null = null): string {
    let cleanUrl = url || window.location.href;

    cleanUrl = cleanUrl.replace(window.location.origin, '');

    return cleanUrl;
}

/**
 * Returns the token char to use for a query string
 */
function getQueryStringToken(url: string): string {
    return url.match(/\?+/) ? '&' : '?';
}

/**
 * Scrolls the element instantly to the given position
 */
function scrollToInstant(element?: Window|HTMLElement, options: ScrollToOptions = {}): void {
    if (!element) {
        return;
    }

    const scrollOptions = {...options, behavior: 'instant'};

    if (!('scrollTo' in element)) {
        return;
    }

    // @ts-ignore
    element.scrollTo(scrollOptions);
}

export {
    cInfo,
    cLog,
    cWarn,
    cError,
    ajaxGet,
    ajaxPost,
    getCleanUrl,
    getQueryStringToken,
    scrollToInstant,
};
